function showHeaderOptions() {
  document.getElementById('header-options').classList.remove('tw-hidden');
}
window.showHeaderOptions = showHeaderOptions;

function showSettingsOptions(settingsButton) {
  settingsButton.parentNode.children[1].classList.toggle('tw-hidden');
  settingsButton.classList.toggle('tw-bg-blue-700');
}
window.showSettingsOptions = showSettingsOptions;

function toggleSettingsHeaderOptions(force = false) {
  const options = document.getElementById('settings-header-options');
  const chevronDown = document.getElementById('chevron-down-icon');
  const chevronUp = document.getElementById('chevron-up-icon');
  if (!options.classList.contains('tw-hidden') || force) {
    options.classList.add('tw-hidden');
    chevronDown.classList.remove('tw-hidden');
    chevronUp.classList.add('tw-hidden');
  } else {
    options.classList.remove('tw-hidden');
    chevronDown.classList.add('tw-hidden');
    chevronUp.classList.remove('tw-hidden');
  }
}
window.toggleSettingsHeaderOptions = toggleSettingsHeaderOptions;

const headerOptions = document.getElementById('header-options');
headerOptions?.addEventListener('click', (event) => {
  if (event.target.classList.contains('tw-sl-modal')) headerOptions.classList.add('tw-hidden');
});

function hideHeaderOptions() {
  document.getElementById('header-options').classList.add('tw-hidden');
  toggleSettingsHeaderOptions(true);
}
window.hideHeaderOptions = hideHeaderOptions;

function toggleSettingsManageOptions(element) {
  const chevronDown = element.querySelector('.chevron-down-icon');
  const chevronUp = element.querySelector('.chevron-up-icon');
  const settingsManageOptions = element.nextElementSibling;
  if (settingsManageOptions.classList.contains('tw-hidden')) {
    settingsManageOptions.classList.remove('tw-hidden');
    chevronUp.classList.remove('tw-hidden');
    chevronDown.classList.add('tw-hidden');
  } else {
    settingsManageOptions.classList.add('tw-hidden');
    chevronUp.classList.add('tw-hidden');
    chevronDown.classList.remove('tw-hidden');
  }
}

window.toggleSettingsManageOptions = toggleSettingsManageOptions;
