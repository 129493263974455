function getUserAgentDevice() {
  const MOBILE_DEVICES = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  if (
    (/Mac/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || MOBILE_DEVICES.test(navigator.userAgent)
  ) {
    return 'ipad/tablet';
  }
  return 'desktop';
}

function googleTagProperties() {
  return {
    userId: window.googleTagDetails.user_id,
    userType: window.googleTagDetails.user_type,
    account: window.googleTagDetails.account,
    accountId: window.googleTagDetails.account_id,
    accountPermissions: window.googleTagDetails.account_permissions,
    state: window.googleTagDetails.state,
    country: window.googleTagDetails.country,
    userAgent: getUserAgentDevice(),
    time: window.googleTagDetails.time,
    date: window.googleTagDetails.date,
  };
}
window.googleTagProperties = googleTagProperties;

function pushLogOutEvent() {
  window.dataLayer.push({
    event: 'logOut',
    ...googleTagProperties(),
  });
}
window.pushLogOutEvent = pushLogOutEvent;

function pushFirstSignInEvent() {
  window.dataLayer.push({
    event: 'firstSignIn',
    accountId: window.googleTagDetails.account_id,
    accountPermissions: window.googleTagDetails.account_permissions,
  });
}
window.pushFirstSignInEvent = pushFirstSignInEvent;

function pushSignUpEvent() {
  // Google Tag Manager seems to not work on first page load, so triggering the google analytics event directly
  window.gtag('event', 'signUp', {
    user_id: window.googleTagDetails.user_id,
    user_type: window.googleTagDetails.user_type,
    state: window.googleTagDetails.state,
    country: window.googleTagDetails.country,
    account: window.googleTagDetails.account,
    account_id: window.googleTagDetails.account_id,
  });
}
window.pushSignUpEvent = pushSignUpEvent;

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#sites-index, #account_setups-show, #users-index')) {
    if (document.getElementById('flash-notice')?.innerHTML === window.I18n.devise.sessions.signed_in) {
      window.dataLayer.push({
        event: 'logIn',
        ...googleTagProperties(),
      });
    }
  }
});

document.getElementById('dashboard-confirm-email')?.addEventListener('click', () => {
  window.dataLayer.push(() => {
    this.reset();
  });
  window.dataLayer.push({
    event: 'signUp',
    accountId: window.googleTagDetails.account_id,
    accountPermissions: window.googleTagDetails.account_permissions,
  });
});

function pushControllerSendCommandEvent(eventName, controllers, commandType) {
  const data = googleTagProperties();
  delete data.accountPermissions;
  window.dataLayer.push({
    event: eventName,
    ...data,
    controllers,
    commandType,
  });
}
window.pushControllerSendCommandEvent = pushControllerSendCommandEvent;

function genericGoogleTagManagerEvent(event, extraBody = {}) {
  window.dataLayer.push({
    event,
    ...googleTagProperties(),
    ...extraBody,
  });
}
window.genericGoogleTagManagerEvent = genericGoogleTagManagerEvent;

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.home-header-link').forEach((link) => {
    link.addEventListener('click', () => { genericGoogleTagManagerEvent('enterHome'); });
  });

  document.querySelectorAll('.insights-header-link').forEach((link) => {
    link.addEventListener('click', () => { genericGoogleTagManagerEvent('enterInsights'); });
  });
});
