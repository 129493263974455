function changeDataFromUrl(url, params) {
  const paramKeys = Object.keys(params);
  const newUrl = new URL(url);
  paramKeys.forEach((paramKey) => {
    const isArray = Array.isArray(params[paramKey]);
    const notEmpty = isArray ? params[paramKey].filter(Boolean).length !== 0 : params[paramKey] !== '';
    if (notEmpty) {
      if (isArray) {
        params[paramKey].filter(Boolean).forEach((arrayParam) => {
          newUrl.searchParams.append(`${paramKey}[]`, arrayParam);
        });
      } else {
        newUrl.searchParams.set(paramKey, params[paramKey]);
      }
    } else {
      newUrl.searchParams.delete(paramKey);
    }
  });
  if (paramKeys.length === 0) {
    return new URL(url.split('?')[0]);
  }
  return newUrl;
}
window.changeDataFromUrl = changeDataFromUrl;

function AddParamsToTailwindPagination(paramsObject) {
  const paginationContainer = document.getElementById('pagination-container');
  if (paginationContainer) {
    [...paginationContainer.children].forEach((listElement) => {
      const tag = listElement.children[0];
      if (tag.href !== '') {
        const newUrl = changeDataFromUrl(tag.href, paramsObject);
        tag.href = newUrl;
      }
    });
  }
  const pathUrl = changeDataFromUrl(window.location, paramsObject);
  window.history.pushState({ newUrl: pathUrl.href }, null, pathUrl);
}
window.AddParamsToTailwindPagination = AddParamsToTailwindPagination;
