function updateSelectedColumns() {
  document.querySelectorAll('[id^="col-select-"]:checked').forEach((input) => {
    document.querySelectorAll(`.${input.id.split('-')[2]}-col`).forEach((col) => col.classList.remove('tw-hidden'));
  });
  document.querySelectorAll('[id^="col-select-"]:not(:checked)').forEach((input) => {
    document.querySelectorAll(`.${input.id.split('-')[2]}-col`).forEach((col) => col.classList.add('tw-hidden'));
  });
  document.getElementById('tailwind-columns').classList.add('tw-hidden');
}
window.updateSelectedColumns = updateSelectedColumns;
