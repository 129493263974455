const addUserModal = document.getElementById('add-user-modal');
addUserModal?.addEventListener('click', (event) => {
  if (event.target.classList.contains('tw-btn-cancel')) addUserModal.classList.add('tw-hidden');
});

function isValidEmail(email) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,14})+$/.test(email);
}
window.isValidEmail = isValidEmail;
function showValidLabel(arrayOfEmails, modal) {
  const validLabel = modal.querySelector('.valid');
  const notValidLabel = modal.querySelector('.not-valid');
  validLabel.classList.add('tw-hidden');
  notValidLabel.classList.add('tw-hidden');
  if (arrayOfEmails.every(isValidEmail)) {
    validLabel.classList.remove('tw-hidden');
    validLabel.textContent = arrayOfEmails.length > 1 ? window.I18n.accounts.users.valid_emails : window.I18n.accounts.users.valid_email;
  } else {
    notValidLabel.classList.remove('tw-hidden');
    notValidLabel.textContent = arrayOfEmails.length > 1 ? window.I18n.accounts.users.not_valid_emails : window.I18n.accounts.users.not_valid_email;
  }
}
window.showValidLabel = showValidLabel;
function enableAddUserButton() {
  const button = addUserModal.querySelector('.btn-action');
  const validLabel = addUserModal.querySelector('.valid');
  if (validLabel.classList.contains('tw-hidden') || addUserModal.querySelector('.li-check') === null) {
    button.classList.remove('tw-btn-action-blue');
    button.classList.add('tw-btn-disabled');
  } else {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-btn-action-blue');
  }
}
window.enableAddUserButton = enableAddUserButton;
function emailValidation(event, modal) {
  const emails = event.target.value;
  event.target.classList.toggle('form_input-typed', event.target.value !== '');
  let arrayOfEmails = emails.split(',');
  arrayOfEmails = arrayOfEmails.map((email) => email.trim());
  showValidLabel(arrayOfEmails, modal);
  if (modal.id === 'add-site-user-modal') {
    window.enableSiteAddUser();
  } else {
    enableAddUserButton();
  }
}
window.emailValidation = emailValidation;

if (addUserModal) {
  const commaSeparatedEmailsInput = addUserModal.querySelector('input');
  commaSeparatedEmailsInput?.addEventListener('input', (event) => { emailValidation(event, addUserModal); });
}

function addUsersToAccount(userEmails, userRole) {
  const confirmationModal = document.getElementById('confirmation-modal');
  confirmationModal.classList.add('tw-hidden');
  const accountId = document.getElementById('account-users-index').dataset.accountId;
  window.$.ajax({
    url: '/account_invites',
    data: { emails: userEmails, account_id: accountId, user_role_id: userRole },
    dataType: 'script',
    type: 'POST',
  });
}
function showAddUserModal() {
  const modal = document.getElementById('add-user-modal');
  modal.classList.remove('tw-hidden');
  modal.querySelector('input').value = '';
  modal.querySelector('input').classList.remove('form_input-typed');
  modal.querySelector('.valid').classList.add('tw-hidden');
  modal.querySelector('.not-valid').classList.add('tw-hidden');
  const button = addUserModal.querySelector('.btn-action');
  button.classList.remove('tw-btn-action-blue');
  button.classList.add('tw-btn-disabled');
}
window.showAddUserModal = showAddUserModal;
function showAddUserConfirmationModal() {
  const confirmationModal = document.getElementById('confirmation-modal');
  addUserModal.classList.add('tw-hidden');
  confirmationModal.classList.remove('tw-hidden');
  const emails = addUserModal.querySelector('input').value;
  let arrayOfEmails = emails.split(',');
  arrayOfEmails = arrayOfEmails.map((email) => email.trim());
  const userRole = addUserModal.querySelector('.li-check').id.split('-')[1];
  confirmationModal.querySelector('.tw-btn-action-blue').onclick = () => addUsersToAccount(arrayOfEmails, userRole);
}
window.showAddUserConfirmationModal = showAddUserConfirmationModal;
